import { Calendar } from '@fullcalendar/core';
import timeGridPlugin from '@fullcalendar/timegrid';

export default () => {

	const calendarEl = document.getElementById('calendar');

	const calendar = new Calendar(calendarEl, {
		plugins: [timeGridPlugin],
		initialView: 'timeGridWeek',
		height: 'auto',
		allDaySlot: false,
		slotMinTime: '6:00',
		slotMaxTime: '22:00',
		headerToolbar: {
			start: 'title',
			center: '',
			end: 'prev,next',
		},
		events: {
			url: env.ajaxUrl,
			method: 'POST',
			extraParams: {
				action: 'get_events'
			}
		},
		loading: isLoading => {
			$('#calendar').toggleClass('loading', isLoading);
		},
		eventClick: (info) => {
			const modal = $('#eventModal');

			if (info.event.extendedProps.programUrl) {
				modal.find('.modal .modal-content').html(`<iframe src="${info.event.extendedProps.programUrl}#session${info.event.extendedProps.sessionId}" />`).scrollTop(0);
				modal.trigger('open');
			}
			else if (info.event.extendedProps.eventUrl) {
				modal.find('.modal .modal-content').html(`<iframe src="${info.event.extendedProps.eventUrl}" />`).scrollTop(0);
				modal.trigger('open');
			}
		}
	});

	if ( calendarEl ) calendar.render();

};