export default () => {

    const modals = $('.modal-overlay');

    modals
        .on('open', e => {
            const modal = $(e.currentTarget);

            modals.removeClass('open');
            modal.addClass('open');

            $('body').addClass('modal-open');
        })
        .on('close', e => {
            const modal = $(e.currentTarget);

            modal.removeClass('open');
            $('body').removeClass('modal-open');
        })
        .each((index, el) => {
            const overlay = $(el);
            const modal = overlay.find('.modal');

            overlay.on('click', e => {
                if (e.target === el) {
                    overlay.trigger('close');
                }
            });

        })

    $('[data-open-modal]')
        .on('click', e => {
            e.preventDefault();
            const modal = $($(e.currentTarget).attr('href'));
            modal.trigger('open');
        })

    $('[data-close-modal]')
        .on('click', e => {
            e.preventDefault();
            const modal = $(e.currentTarget).closest('.modal-overlay');
            modal.trigger('close');
        })

}