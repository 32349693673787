import Modal from './modal';

export default () => {

    const form = $('form[data-contact]');

    // modals

    Modal();

    // testing

    const populateForm = () => {
        // parent
        form.find(':input[name="parent[name]"]').val('John Doe');
        form.find(':input[name="parent[email]"]').val('john@acme.ca');
        form.find(':input[name="parent[phone]"]').val('905-123-4567');
        // child
        form.find(':input[name="child[sport]"]').val('Horse racing');
        form.find(':input[name="child[program]"]').val('Hockey');
        form.find(':input[name="child[age]"]').val('15');
    }

    form
        .on('submit', e => {
            const formData = form.serialize();
            $('body').addClass('submitting');

            $.getJSON({
                url: env.ajaxUrl,
                type: 'post',
                data: formData
            })
                .done((response) => {
                    $('body').removeClass('submitting');

                    if (response.success) {
                        alert(response.data.message);
                        form.trigger('reset');
                        form.find('.modal-overlay').trigger('close');
                    } else {
                        alert('There was a problem');
                    }
                });

            return false;
        });

}