import Modal from './modal';
import '@chenfengyuan/datepicker';
import Readmore from 'readmore-js';

export default () => {

    const form = $('form[data-registration]');
    const sessions = form.find('[data-session]');
    const sessionList = form.find('.session-list');
    const responseMessage = form.find('[data-response-message]');
    const fieldsets = form.find('[data-fieldset]');
    const waivers = form.find('[data-waiver]');
    const sessionInput = form.find(':input[name=session]');
    const variationInput = form.find(':input[name=variation]');
    const priceInput = form.find(':input[name=price]');

    let stripe = Stripe('pk_test_51H4IG9BxCBrBn9aBMSqshynXyTobLXgPqocMQRNEIHI8EyoL2TsKuHdp84ETkDOwPplaVtmPodhG9Xo2c4UrHw6q00cifI2pw7');
    if (env.env === 'production') {
        stripe = Stripe('pk_live_51H4IG9BxCBrBn9aBwh6JYOzAaFTZNNmGs1vc5eLpROvsVZEpWjpoaWnM1HzVBVz01kYBRfWgs6VSQphAvIc3Rpyu00DOxK83dA');
    }

    // init form

    responseMessage.removeClass('error success').hide();
    fieldsets.hide();
    waivers.hide();
    new Readmore('.session .session-body article', {
        moreLink: '<a href="#" class="read-more">Read more <svg class="icon"><use xlink:href="#sprite-arrow-right"></use></svg></a>',
        lessLink: '<a href="#" class="read-less">Read less <svg class="icon"><use xlink:href="#sprite-arrow-left"></use></svg></a>',
    });

    $('[data-toggle="datepicker"]').datepicker({
        format: 'yyyy-mm-dd',
        autoHide: true
    });

    // sort

    sessions.sort((a, b) => {
        const orderA = parseInt($(a).data('order'));
        const orderB = parseInt($(b).data('order'));
        return (orderA < orderB) ? -1 : (orderA > orderB) ? 1 : 0;
    }).appendTo(sessionList);

    // modals

    Modal();

    // testing

    const populateForm = () => {
        // parent
        form.find(':input[name="parent[name]"]').val('John Doe');
        form.find(':input[name="parent[email]"]').val('john@acme.ca');
        form.find(':input[name="parent[phone]"]').val('905-123-4567');
        form.find(':input[name="parent[address]"]').val('123 Fake Street');
        form.find(':input[name="parent[city]"]').val('Toronto');
        form.find(':input[name="parent[province]"]').val('ON');
        form.find(':input[name="parent[postal_code]"]').val('M5M 5M5');
        // child
        form.find(':input[name="child[name]"]').val('Madison Doe');
        form.find(':input[name="child[dob]"]').val('2009-10-02');
        form.find(':input[name="child[gender]"]').val('female');
    }

    // choose variation

    $(document)
        .on('click', '[data-select-variation]', e => {
            e.preventDefault();

            const button = $(e.currentTarget);
            const sessionId = button.data('selectVariation').split(',')[0];
            const variationId = button.data('selectVariation').split(',')[1];
            const spaceAvailable = button.data('spaceAvailable');
            const waiverIds = button.data('waivers') ? button.data('waivers').split(',') : [];
            const price = button.data('price') || 0;

            if ( spaceAvailable && !price ) {
                alert('Sorry, there has been a problem: price is not set correctly');
                return;
            }

            // response/alert

            responseMessage.hide();
            
            // waivers

            waivers
                .hide()
                .each(function(index, el) {
                    const visible = waiverIds.indexOf($(el).data('waiver').toString()) > -1;
                    $(el)
                        .toggle(visible)
                        .find(':input')
                        .prop('disabled', !visible);
                });

            // form fieldsets

            fieldsets
                .hide()
                .prop('disabled', true)
                .filter(`[data-space-available=${spaceAvailable}]`)
                .show()
                .prop('disabled', false)
                .closest('.modal-overlay')
                .trigger('open');

            sessionInput.val(sessionId);
            variationInput.val(variationId); 
            priceInput.val(price);           

            if (env.env === 'local') {
                populateForm();
            }
        });    

    // form submit handler

    form
        .on('submit', e => {
            const formData = form.serialize();
            $('body').addClass('submitting');

            $.getJSON({
                url: env.ajaxUrl,
                type: 'post',
                data: formData
            })
                .done((response) => {
                    $('body').removeClass('submitting');

                    if (response.success) {

                        // do payment
                        if (response.data.session_id) {
                            stripe.redirectToCheckout({
                                sessionId: response.data.session_id
                            }).then(function (result) {
                                responseMessage.addClass('error').text('Payment failed: ' + result.error.message).show();
                                fieldsets.hide();
                            });
                        }

                        if (response.data.message) {
                            responseMessage.addClass('success').text(response.data.message).show();
                            fieldsets.hide();
                        }

                    } else {
                        responseMessage.addClass('error').text('There was a problem').show();
                    }
                });

            return false;
        });

    // load up session

    if ( env.session ) {
        const modal = $('#sessionModal');
        const sessionId = env.session;
        const session = $(`[data-session=${sessionId}]`);
        if ( session.length ) {
            modal.find('.modal .modal-content').html(session.html()).scrollTop(0);
            modal.trigger('open');    
        }
    }

}