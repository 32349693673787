import Registration from '../lib/registration';
import Contact from '../lib/contact';
import Calendar from '../lib/calendar';

export default {
  init() {
    // JavaScript to be fired on all pages
    console.log('common');
  },
  finalize() {
    Registration();
    Contact();
    Calendar();

    // mailto

    $('[data-mailto]')
      .each((index, el) => {
        let text = $(el).text();
        $(el).html(text.replace(/([\w\.\-\pL]+@\w+\.\w+)/g, '<a href="mailto:$1">$1</a>'));
      });

  },
};
